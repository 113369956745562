import styled, { keyframes } from "styled-components";

import React from "react";

const Input = styled.input`
  z-index: -1;
  opacity: 0;
  position: absolute;
  left: 0;
  pointer-events: none;
`;

const popIn = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5) ;
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) ;
}
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  margin: 0.5rem 2rem 0.5rem 0;
  font-family: 'Eina';
  font-size: 14px;
  letter-spacing: 0.2px;
  color: white;
`;

const Indicator = styled.div`
  border: 1px solid #d8d8e3;
  border-radius: 1em;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0em;

  /* ${Label}:hover & {
    background: #ccc;
  } */

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    border: solid #db6e6b;
    border-radius: 1em;
    background-color: #db6e6b;
    width: 9px;
    height: 9px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  ${Input}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
  }
`;

export default function RadioButton({
  value,
  onChange,
  name,
  id,
  label,
  disabled,
  checked,
  required
}) {
  return (
    <Label htmlFor={id} disabled={disabled}>
      <span style={{marginLeft: '1.625rem'}}>{label}</span>
      <Input
        id={id}
        type="radio"
        role="radio"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        defaultChecked={checked}
        required={required}
      />
      <Indicator />
    </Label>
  );
}
