import { CAMRoleOptions, Container, CustomSelect, FieldContainer, FormButtonContainer, FormContainer, HelperText, Input, Label, MessageInput, NavBar, Overlay, ServicesOptions, Title, currenciesOptions } from '../../components/contact-us/contact-us-form-elements';

import Background from '../../img/contact-us-bg.jpeg';
import Checkbox from '../../components/Checkbox';
import Layout from '../../components/Layout';
import { Link } from 'gatsby';
import RadioButton from '../../components/RadioButton';
import React from 'react';
import logo from '../../img/logo.svg';
import { navigate } from 'gatsby';
import styled from 'styled-components';

const ContactUsPageCAMService = () => {

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [workedWithCamaBefore, setWorkedWithCamaBefore] = React.useState(undefined);
  const [projectName, setProjectName] = React.useState('');
  const [budget, setBudget] = React.useState(undefined);
  const [currency, setCurrency] = React.useState('');
  const [isCoProduction, setIsCoProduction] = React.useState(undefined);
  const [stageOfFinancing, setStageOfFinancing] = React.useState(undefined);
  const [financiersInvolved, setFinanciersInvolved] = React.useState(undefined);
  const [salesAttached, setSalesAttached] = React.useState(undefined);
  const [beneficiaries, setBeneficiaries] = React.useState('');
  const [role, setRole] = React.useState('');
  const [additionalNotes, setAdditionalNotes] = React.useState('');
  const [subscribeToNewsletter, setSubscribeToNewsletter] = React.useState(false);
  const [privacyTerms, setPrivacyTerms] = React.useState(false);


  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleSubmit = (e) => {
    const data = { 
      'form-name': 'contact-cam-sevice',
      typeOfService: 'CAM Service',
      name,
      email,
      phone,
      workedWithCamaBefore: workedWithCamaBefore ? 'Yes' : 'No',
      projectName: projectName,
      budget: budget,
      currency: currency,
      isCoProduction: isCoProduction ? 'Yes' : 'No',
      stageOfFinancing: stageOfFinancing ? 'Up to 70%' : 'Over 70%',
      financiersInvolved: financiersInvolved ? 'Yes' : 'No',
      salesAttached: salesAttached ? 'Yes' : 'No',
      beneficiaries,
      role,
      additionalNotes,
      subscribeToNewsletter: subscribeToNewsletter ? 'Yes' : 'No',
      privacyTerms: privacyTerms ? 'Yes' : 'No',
    };
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    });

    setFormSubmitted(true);
    e.preventDefault();
  };

  return (
    <Layout contactus={true}>
      <Container>
        <img src={Background} alt="" className="hero-background" style={{opacity: 0.6, zIndex: '-1', position: 'fixed'}} />
        <NavBar>
          <Link to="/" title="Logo">
            <img src={logo} alt="FilmChain" className="filmchain-icon" />
          </Link>
        </NavBar>
        <Overlay />
        <FormContainer>
          <Title
            className="h1-gradient"
          >
            Contact us
          </Title>
          <Label>
            {'What service are you interested in?'}{' '}
            <CustomSelect 
              options={ServicesOptions}
              classNamePrefix="fc-select"
              defaultValue={ServicesOptions[0]}
              onChange={(e) => { navigate(`/contact-us/${e.value}`) }}
            />
          </Label>
          <form
            name="contact-cam-sevice"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="typeOfService" value="CAM Service" />
            <FieldContainer>
              <Label>
                Name
                <Input
                  className="input"
                  type="text"
                  name="name"
                  placeholder='Type your name'
                  autocomplete="off"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Label>
            </FieldContainer>
            
            <FieldContainer>
              <Label>
                Email
                <Input
                  className="input"
                  type="email"
                  name="email"
                  placeholder='Type your email address'
                  autocomplete="off"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Label>
            </FieldContainer>
            
            <FieldContainer>
              <Label>
                Phone (optional)
                <Input
                  className="input"
                  type="phone"
                  name="phone"
                  placeholder='Type your phone number'
                  autocomplete="off"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <HelperText>Please include the country code</HelperText>
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                Have you worked with a CAMA before?
                <div>
                  <RadioButton
                    label="Yes"
                    name="workedWithCamaBefore"
                    value={workedWithCamaBefore !== undefined ? workedWithCamaBefore : undefined}
                    checked={workedWithCamaBefore !== undefined  ? workedWithCamaBefore : undefined}
                    onChange={({ target }) => {
                      setWorkedWithCamaBefore(Boolean(true));
                    }}
                    required
                  />
                  <RadioButton
                    label="No"
                    name="workedWithCamaBefore"
                    value={workedWithCamaBefore !== undefined ? !workedWithCamaBefore : undefined}
                    checked={workedWithCamaBefore !== undefined ? !workedWithCamaBefore : undefined}
                    onChange={({ target }) => {
                      setWorkedWithCamaBefore(Boolean(false));
                    }}
                    required
                  />
                </div>
              </Label>
            </FieldContainer>
        
            <FieldContainer>
              <Label>
                What is the name of your project?
                <Input
                  className="input"
                  type="text"
                  name="projectName"
                  placeholder='Type your project name'
                  autocomplete="off"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  required
                />
              </Label>
            </FieldContainer>

            <TwoColumnsContainer>

            <FieldContainer>
              <Label>
                What is the budget of your project?
                <Input
                  className="input"
                  style={{minWidth: 'inherit', minHeight:'38px'}}
                  type="number"
                  name="budget"
                  placeholder='Type budget'
                  autocomplete="off"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  required
                />
              </Label>
            </FieldContainer>
            <FieldContainer>
              <Label>
                Currency
                <CustomSelect 
                  options={currenciesOptions}
                  classNamePrefix="fc-select"
                  name="currency"
                  placeholder="Choose currency"
                  onChange={(e) => { setCurrency(e.value) }}
                  required
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    width: "100%",
                    height: 0,
                    position: "absolute"
                  }}
                  onChange={() => {}}
                  value={currency}
                  required
                />
              </Label>
            </FieldContainer>
            </TwoColumnsContainer>

            <FieldContainer>
              <Label>
                Is it a co-production?
                <div>
                  <RadioButton
                    label="Yes"
                    name="isCoProduction"
                    value={isCoProduction !== undefined ? isCoProduction : undefined}
                    checked={isCoProduction !== undefined ? isCoProduction : undefined}
                    onChange={({ target }) => {
                      setIsCoProduction(Boolean(true));
                    }}
                    required
                  />
                  <RadioButton
                    label="No"
                    name="isCoProduction"
                    value={isCoProduction !== undefined ? !isCoProduction : undefined}
                    checked={isCoProduction !== undefined ? !isCoProduction : undefined}
                    onChange={({ target }) => {
                      setIsCoProduction(Boolean(false));
                    }}
                    required
                  />
                </div>
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                What stage of financing are you at?
                <div>
                  <RadioButton
                    label="Up to 70%"
                    name="stageOfFinancing"
                    value={stageOfFinancing !== undefined ? stageOfFinancing : undefined}
                    checked={stageOfFinancing !== undefined ? stageOfFinancing : undefined}
                    onChange={({ target }) => {
                      setStageOfFinancing(Boolean(true));
                    }}
                    required
                  />
                  <RadioButton
                    label="Over 70%"
                    name="stageOfFinancing"
                    value={stageOfFinancing !== undefined ? !stageOfFinancing : undefined}
                    checked={stageOfFinancing !== undefined ? !stageOfFinancing : undefined}
                    onChange={({ target }) => {
                      setStageOfFinancing(Boolean(false));
                    }}
                    required
                  />
                </div>
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                Are any public financiers involved?
                <div>
                  <RadioButton
                    label="Yes"
                    name="financiersInvolved"
                    value={financiersInvolved !== undefined ? financiersInvolved : undefined}
                    checked={financiersInvolved !== undefined ? financiersInvolved : undefined}
                    onChange={({ target }) => {
                      setFinanciersInvolved(Boolean(true));
                    }}
                    required
                  />
                  <RadioButton
                    label="No"
                    name="financiersInvolved"
                    value={financiersInvolved !== undefined ? !financiersInvolved : undefined}
                    checked={financiersInvolved !== undefined ? !financiersInvolved : undefined}
                    onChange={({ target }) => {
                      setFinanciersInvolved(Boolean(false));
                    }}
                    required
                  />
                </div>
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                Do you have Sales attached/in negotiations?
                <div>
                  <RadioButton
                    label="Yes"
                    name="salesAttached"
                    value={salesAttached !== undefined ? salesAttached : undefined}
                    checked={salesAttached !== undefined ? salesAttached : undefined}
                    onChange={({ target }) => {
                      setSalesAttached(Boolean(true));
                    }}
                    required
                  />
                  <RadioButton
                    label="No"
                    name="salesAttached"
                    value={salesAttached !== undefined ? !salesAttached : undefined}
                    checked={salesAttached !== undefined ? !salesAttached : undefined}
                    onChange={({ target }) => {
                      setSalesAttached(Boolean(false));
                    }}
                    required
                  />
                </div>
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                How many beneficiaries are due to recoup revenues?
                <Input
                  className="input"
                  type="number"
                  name="beneficiaries"
                  placeholder='Type the number of beneficiaries'
                  autocomplete="off"
                  value={beneficiaries}
                  onChange={(e) => setBeneficiaries(e.target.value)}
                  required
                />
              </Label>
            </FieldContainer>
            
            <FieldContainer>
              <Label>
                What is your role in the project?
                <CustomSelect 
                  options={CAMRoleOptions}
                  classNamePrefix="fc-select"
                  name="role"
                  placeholder="Choose role"
                  onChange={(e) => { setRole(e.value) }}
                  required
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    width: "100%",
                    height: 0,
                    position: "absolute"
                  }}
                  onChange={() => {}}
                  value={role}
                  required
                />
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                Additional notes
                <MessageInput
                  className="input"
                  autocomplete="off"
                  placeholder='Anything else you would like us to know'
                  value={additionalNotes}
                  onChange={(e) => setAdditionalNotes(e.target.value)}
                  name="additionalNotes"
                />
              </Label>
            </FieldContainer>

            <FieldContainer style={{ marginTop: '28px'}}>
              <Checkbox
                id="subscribeToNewsletter"
                name="subscribeToNewsletter"
                label="I would like to subscribe to the FilmChain newsletter."
                value={subscribeToNewsletter}
                checked={subscribeToNewsletter}
                onChange={({ target }) => setSubscribeToNewsletter(!subscribeToNewsletter)}
              />
            </FieldContainer>

            <FieldContainer>
              <Checkbox
                id="privacyTerms"
                name="privacyTerms"
                label={["I agree with FilmChain’s ", <a key="privacy-link" href="/privacy" target="_blank" rel="noopener noreferrer"  style={{textDecoration: "underline", color: 'white' }}>Privacy terms</a>]}
                value={privacyTerms}
                checked={privacyTerms}
                onChange={({ target }) => setPrivacyTerms(!privacyTerms)}
                required
              />
            </FieldContainer>
            

            <FormButtonContainer>
              <button className="send btn inverted" style={{ marginTop: '48px', display: 'block', margin: 0 }} type="submit"
                ref={(el) => {
                  if (el && formSubmitted) {
                    el.style.setProperty('background-color', '#3ccf74', 'important');
                    el.style.setProperty('background-image', 'none', 'important');
                    el.style.setProperty('pointer-events', 'none', 'important');
                  }
                }}
              >
                {formSubmitted ? 'Sent' : 'Send'}
              </button>
              { formSubmitted && <p>Thank you! We’ll get back to you shortly!</p> } 
            </FormButtonContainer>
           
          </form>
        </FormContainer>
       
      </Container>
    </Layout>
  );
};


export default ContactUsPageCAMService;

const TwoColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;

  @media screen and (min-width: 560px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 769px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 1300px) {
      grid-template-columns: 1fr 1fr;
  }

`;